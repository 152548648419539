import { base, polygon } from "viem/chains"

// #region URLs

export const TERMS_AND_SERVICES_URL = "https://assets.iyk.app/iyk-terms-of-service.pdf"
export const PRIVACY_POLICY_URL = "https://assets.iyk.app/iyk-privacy-policy.pdf"
export const SHIPPING_RETURNS_URL =
  "https://iyk.notion.site/Shipping-Returns-40ce22513b664e5eb1f7773033896370"
export const HOW_TO_TAP_URL = "https://iyk.app/how-to-tap"
export const CAREERS_URL = "https://iyk.app/careers"
export const FAQ_URL = "https://iyk.app/faq"

export const DISCORD_URL = "https://discord.gg/ezppV2nj7w"
export const TWITTER_URL = "https://twitter.com/iyk_app"
export const INSTAGRAM_URL = "https://instagram.com/iyk_app"
export const TIKTOK_URL = "https://tiktok.com/@iyk_app"
export const WARPCAST_URL = "https://warpcast.com/iyk"

export const IYK_LOGO_URL = "https://iyk.app/images/iyk-avatar.svg"

// #endregion

export const IYK_SUPPORT_EMAIL = "help@iyk.app"

export const V2_USER_WALLET_TYPES = {
  BASTION: "bastion",
  EXTERNAL: "external",
} as const

export const V2_USER_WALLET_NAMES = {
  BASTION: "IYK Wallet",
  EXTERNAL: "User Wallet",
} as const

export const TOKENS = {
  DEV_TESTING_REF: {
    chainId: polygon.id,
    contractAddress: "0x05ef3c8c1d0c5326686c70c032f3077f3c8e95ac",
  },
  GLIMMERS: {
    chainId: base.id,
    contractAddress: "0x453b53abe701dfb172e78e6d3f3be54acc502768",
  },
  TOM_WALKER: {
    chainId: base.id,
    contractAddress: "0x607c03e2d1435a3de8cdf41d1f2e99d6e62ac40a",
  },
  GRAHAM_BARHAM_SHOOT_THE_LOCK: {
    chainId: base.id,
    contractAddress: "0x88123d03948da12006a48c272ab7bcbc181348c8",
    startTokenId: 1,
    endTokenId: 375,
  },
  GRAHAM_BARHAM_WHISKEY_WHISKEY: {
    chainId: base.id,
    contractAddress: "0x88123d03948da12006a48c272ab7bcbc181348c8",
    startTokenId: 376,
    endTokenId: 750,
  },
  SKIZZY_AND_MARC_FOLK_HEROES: {
    chainId: base.id,
    contractAddress: "0xa843f5ccd1879bc102f39198927158ec3b6b8d17",
    startTokenId: 1,
    endTokenId: 60,
  },
  MARC_FOLK_HEROES: {
    chainId: base.id,
    contractAddress: "0xa843f5ccd1879bc102f39198927158ec3b6b8d17",
    startTokenId: 61,
    endTokenId: 130,
  },
  SKIZZY_FOLK_HEROES: {
    chainId: base.id,
    contractAddress: "0xa843f5ccd1879bc102f39198927158ec3b6b8d17",
    startTokenId: 131,
    endTokenId: 202,
  },
  MASTERCARD_OTW: {
    chainId: base.id,
    contractAddress: "0x09669e93523fa0b5c8310f7bdb036b139a020d80",
  },
  BRING_ME_THE_HORIZON_DEMO: {
    chainId: base.id,
    contractAddress: "0x8956dc80b534a57e9366b72f0850579a440c1825",
  },
  BRING_ME_THE_HORIZON: {
    chainId: base.id,
    contractAddress: "0x83bdf01fe69006a092ef5812c3b8d5c2a4b48dcb",
  },
  RUDY_IN_DA_HOUSE_DEMO: {
    chainId: base.id,
    contractAddress: "0xca7736900723429c6246e72de89d08e8c52a1175",
  },
  RUDY_IN_DA_HOUSE: {
    chainId: base.id,
    contractAddress: "0x9fb85c0210aed19564733b6140e31565cefac7b2",
  },
  CALVIN_HARRIS_ALBUM_DEMO: {
    chainId: base.id,
    contractAddress: "0xc53ee86a0a6bc34cd474793ec489ecaa734c2d61",
    startTokenId: 2,
    endTokenId: 3,
  },
  CALVIN_HARRIS_TICKET_DEMO: {
    chainId: base.id,
    contractAddress: "0xc53ee86a0a6bc34cd474793ec489ecaa734c2d61",
    startTokenId: 4,
    endTokenId: 4,
  },
  MIYAVI_LOST_IN_LOVE: {
    chainId: base.id,
    contractAddress: "0x8ae72e4123c4e039781374bd5321fee83ff1fb69",
  },
  BROOKE_ALEXXX_MASTERCARD_OTW: {
    chainId: base.id,
    contractAddress: "0x9f845d81d2522a4322f1bc68e35eca5db41012a6",
  },
  EARTHGANG_DEMO: {
    chainId: base.id,
    contractAddress: "0xa2133207050a0c6ad9417eda52b46a9e16102ab3",
  },
  MNTGE_ANYMA_DEMO: {
    chainId: polygon.id,
    contractAddress: "0x19ed7fd3219e07a2c032ae6e2401ea2ebddd2fb6",
  },
  BRADLEY_SIMPSON: {
    chainId: base.id,
    contractAddress: "0x731f40a1fad946fb8a4a076842918f430c096243",
  },
  GUY: {
    chainId: base.id,
    contractAddress: "0x24d71dc930def988dd61e3c2360676ae28866652",
  },
  BURY_TOMORROW: {
    chainId: base.id,
    contractAddress: "0x5ecee91892a426962635d960a57ae64ee0588102",
  },
  VINYL_KEY: {
    chainId: base.id,
    contractAddress: "0x57922ec3dbb51832384e5da56242e5c53e32b6ea",
  },
  AKON: {
    chainId: base.id,
    contractAddress: "0xe95c27426b629f45779d9c4595ae4034aee1507b",
  },
} as const

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000"
