import * as React from "react"

import mixpanel from "mixpanel-browser"

import { useRootLoaderData } from "./use-root-loader-data.ts"

export const useIykUser = () => {
  const rootData = useRootLoaderData()

  // Track user in Mixpanel
  React.useEffect(() => {
    if (rootData.user) {
      mixpanel.identify(rootData.user.id)

      const defaultWallet = rootData.user.v2UserWallets.find(
        (wallet) => wallet.id === rootData.user?.defaultV2UserWalletId,
      )

      mixpanel.people.set({
        $email: rootData.user.email,
        $name: rootData.user.username,
        $created: rootData.user.createdAt,
        $avatar: rootData.user.avatarUrl,
        defaultWalletId: defaultWallet?.id,
        defaultWalletAddress: defaultWallet?.address,
        defaultWalletType: defaultWallet?.type,
      })
    } else {
      mixpanel.reset()
    }
  }, [rootData.user?.id])

  return rootData.user
    ? { isSignedIn: true as const, iykUser: rootData.user }
    : { isSignedIn: false as const, iykUser: null }
}

export type IykUser = NonNullable<ReturnType<typeof useIykUser>["iykUser"]>
